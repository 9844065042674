@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Righteous&family=Zilla+Slab:wght@400;600&display=swap');

$primary: #220040;
$secondary: #C7DBE6;
$success: #5B9279;
// $info:
$warning: #E9B872;
$danger: #FE5F55;
$light: #efefef;
$dark: #1f1f1f;

$family-primary: 'Zilla Slab', serif;
$family-secondary: 'Righteous', cursive;

$navbar-background-color: $light;
$body-background-color: $light;


@import "~bulma/bulma";

section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

nav {
    font-family: $family-secondary, Roboto, sans-serif;
}
#nav-toggle-state {
    display: none;
}
#nav-toggle-state:checked ~ .nav-menu {
    display:block;
}

.brand-logo {
    fill: $primary;
    height: 2rem;
}
.brand-text {
    padding: 0 0.4rem;
    color: $primary;
    font-size: 1.2rem;
}

.hero.is-primary .subtitle {
    color: $secondary;
}

.tile {
    color: $dark;

    figure {
        margin: 0 auto;
        margin-bottom: 1rem;
    }
}
